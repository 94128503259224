import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_PRODUCT_URL , ADD_PRODUCT_URL, DELETE_PRODUCT_URL, MULTIPLE_PRODUCT_DELETE_URL, UPDATE_PRODUCT_URL, DELETE_PRODUCT_IMAGE_URL, DELETE_PRODUCT_BANNER_IMAGE_URL } from '../../apis/url_constants';

// Action Types
export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';


export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const MULTIPLE_DELETE_PRODUCT_SUCCESS = 'MULTIPLE_DELETE_PRODUCT_SUCCESS';

export const DELETE_PRODUCT_IMAGE_SUCCESS = 'DELETE_PRODUCT_IMAGE_SUCCESS';
export const DELETE_PRODUCT_BANNER_IMAGE_SUCCESS = 'DELETE_PRODUCT_BANNER_IMAGE_SUCCESS';

export const fetchProductRequest = (id) => async (dispatch) => {
  dispatch({ type: FETCH_PRODUCT_REQUEST });
  const token = localStorage.getItem("token");

  try {
    let endpoint = GET_PRODUCT_URL;

    if (id) {
      endpoint += `/${id}`;
    }

    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response)

    if (id) {
      const product = response.data;
      dispatch({
        type: FETCH_PRODUCT_SUCCESS,
        payload: [product],
      });
    } else {
      const productList = response.data;

      dispatch({
        type: FETCH_PRODUCT_SUCCESS,
        payload: productList,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_PRODUCT_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data);
  }
};

  
  export const fetchProductSuccess = (product) => ({
    type: FETCH_PRODUCT_SUCCESS,
    payload: product,
  });
  
  export const fetchProductFailure = (error) => ({
    type: FETCH_PRODUCT_FAILURE,
    payload: error,
  });
  
  export const addProductRequest = (product, navigate) => async (dispatch) => {
    dispatch({ type: ADD_PRODUCT_REQUEST });
    const token = localStorage.getItem('token');

    console.log(product)
    try {
        const formData = new FormData(); 
        
        formData.append('Name', product.Name);
        formData.append('Slug', product.Slug);
        formData.append('Image', product.Image);
        formData.append('Images', product.Images);
        formData.append('BannerImages', product.BannerImages);
        formData.append('Description', product.Description);
        formData.append('ShortDescription', product.ShortDescription);
        formData.append('HasOptions', product.HasOptions);
        formData.append('BasePrice', product.BasePrice);
        formData.append('DiscountAmount', product.DiscountAmount);
        formData.append('ProductTags', product.ProductTags);
        formData.append('AvailableQty', product.AvailableQty);
        formData.append('FreeShipping', product.FreeShipping);
        formData.append('Status', product.Status);
        formData.append('EnableTaxes', product.EnableTaxes);
        formData.append('OutOfStock', product.OutOfStock);
        formData.append('InStock', product.InStock);
        formData.append('Title', product.Title);
        formData.append('MetaDescription', product.MetaDescription);
        formData.append('MetaKeywords', product.MetaKeywords);
        formData.append('SKU', product.SKU);
        formData.append('SizeChart', product.SizeChart);
        formData.append('SortOrder', product.SortOrder);
        formData.append('GuaranteeWarranty', product.GuaranteeWarranty);
        formData.append('BuyNow', product.BuyNow);
        formData.append('AddToCart', product.AddToCart);
        formData.append('Wishlist', product.Wishlist);
        formData.append('Enquiry', product.Enquiry);
        formData.append('Whatsapp', product.Whatsapp);
        formData.append('HasFAQs', product.HasFAQs);
        formData.append('BestSeller', product.BestSeller);
        formData.append('NewArrival', product.NewArrival);
        formData.append('Trending', product.Trending);

        if (product.ProductCategories.length > 0) {
          product.ProductCategories.forEach((product) => {
            formData.append('ProductCategories', product);
          });
        }

        if (product.ProductSubCategories.length > 0) {
          product.ProductSubCategories.forEach((product) => {
            formData.append('ProductSubCategories', product);
          });
        }

        if (product.ProductTaxes.length > 0) {
          product.ProductTaxes.forEach((product) => {
            formData.append('ProductTaxes', product);
          });
        }

        product.Options.forEach((option, index) => {
          formData.append(`Options[${index}].OptionName`, option.OptionName);
          formData.append(`Options[${index}].OptionValue`, option.OptionValue);
        });
        
        // Set the 'ProductFaqs' property (assuming you have a similar model for ProductFaqs)
        product.ProductFaqs.forEach((faq, index) => {
          formData.append(`ProductFaqs[${index}].Question`, faq.Question);
          formData.append(`ProductFaqs[${index}].Answer`, faq.Answer);
          formData.append(`ProductFaqs[${index}].SortOrder`, faq.SortOrder);
        });

       

        if (product.RelatedProducts.length > 0) {
          product.RelatedProducts.forEach((product) => {
            formData.append('RelatedProducts', product);
          });
        }

        for (let i = 0; i < product.Images.length; i++) {
          formData.append('Images', product.Images[i]);
        }

        for (let i = 0; i < product.BannerImages.length; i++) {
          formData.append('BannerImages', product.BannerImages[i]);
        }
    

      const response = await axios.post(ADD_PRODUCT_URL, formData, {
          headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          },
      });

      dispatch({ type: ADD_PRODUCT_SUCCESS, payload: [response.data] });
      navigate('/product'); 

  } catch (error) {
    dispatch({
      type: ADD_PRODUCT_FAILURE,
      payload: error.message,
    });
    toast.error(error.response.data)
  }
  };
  
  export const addProductSuccess = () => ({
    type: ADD_PRODUCT_SUCCESS,
  });
  
  export const addProductFailure = (error) => ({
    type: ADD_PRODUCT_FAILURE,
    payload: error,
  });


  export const deleteProductRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      await axios.delete(`${DELETE_PRODUCT_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: DELETE_PRODUCT_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      dispatch({
        type: DELETE_PRODUCT_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete About Us');
    }
  };

  export const deleteProductSuccess = () => ({
    type: DELETE_PRODUCT_SUCCESS,
  });
  
  export const deleteProductFailure = (error) => ({
    type: DELETE_PRODUCT_FAILURE,
    payload: error,
  });


  export const multipleDeleteProductRequest = (ids) => async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(MULTIPLE_PRODUCT_DELETE_URL, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      dispatch({ type: MULTIPLE_DELETE_PRODUCT_SUCCESS, payload: ids });

    } catch (error) {
      console.log(error);
      dispatch({
        type: DELETE_PRODUCT_FAILURE,
        payload: error.message,
      });
      // Handle error message if needed
    }
  };
  
  export const updateProductRequest = (product, id , navigate) => async (dispatch) => {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    const token = localStorage.getItem('token');

    console.log(product);
    try {

      const formData = new FormData();
        formData.append('Id', id);
        formData.append('Name', product.Name);
        formData.append('Slug', product.Slug);
        formData.append('Image', product.Image);
        formData.append('Images', product.Images);
        formData.append('BannerImages', product.BannerImages);
        formData.append('Description', product.Description);
        formData.append('ShortDescription', product.ShortDescription);
        formData.append('HasOptions', product.HasOptions);
        formData.append('BasePrice', product.BasePrice);
        formData.append('DiscountAmount', product.DiscountAmount);
        formData.append('ProductTags', product.ProductTags);
        formData.append('AvailableQty', product.AvailableQty);
        formData.append('FreeShipping', product.FreeShipping);
        formData.append('Status', product.Status);
        formData.append('EnableTaxes', product.EnableTaxes);
        formData.append('OutOfStock', product.OutOfStock);
        formData.append('InStock', product.InStock);
        formData.append('Title', product.Title);
        formData.append('MetaDescription', product.MetaDescription);
        formData.append('MetaKeywords', product.MetaKeywords);
        formData.append('SKU', product.SKU);
        formData.append('SizeChart', product.SizeChart);
        formData.append('SortOrder', product.SortOrder);
        formData.append('GuaranteeWarranty', product.GuaranteeWarranty);
        formData.append('BuyNow', product.BuyNow);
        formData.append('AddToCart', product.AddToCart);
        formData.append('Wishlist', product.Wishlist);
        formData.append('Enquiry', product.Enquiry);
        formData.append('Whatsapp', product.Whatsapp);
        formData.append('HasFAQs', product.HasFAQs);
        formData.append('BestSeller', product.BestSeller);
        formData.append('NewArrival', product.NewArrival);
        formData.append('Trending', product.Trending);
        formData.append('ShapeId', product.ShapeId);
        formData.append('Carat', product.Carat);
        formData.append('Clarity', product.Clarity);
        formData.append('Color', product.Color);

        if (product.ProductCategories.length > 0) {
          product.ProductCategories.forEach((product) => {
            formData.append('ProductCategories', product);
          });
        }

        if (product.ProductSubCategories.length > 0) {
          product.ProductSubCategories.forEach((product) => {
            formData.append('ProductSubCategories', product);
          });
        }

        if (product.ProductTaxes.length > 0) {
          product.ProductTaxes.forEach((product) => {
            formData.append('ProductTaxes', product);
          });
        }

        if (product.RelatedProducts.length > 0) {
          product.RelatedProducts.forEach((product) => {
            formData.append('RelatedProducts', product);
          });
        }

        for (let i = 0; i < product.Images.length; i++) {
          formData.append('Images', product.Images[i]);
        }

        for (let i = 0; i < product.BannerImages.length; i++) {
          formData.append('BannerImages', product.BannerImages[i]);
        }

        product.Options.forEach((option, index) => {
          formData.append(`Options[${index}].OptionName`, option.OptionName);
          formData.append(`Options[${index}].OptionValue`, option.OptionValue);
        });
        
        // Set the 'ProductFaqs' property (assuming you have a similar model for ProductFaqs)
        product.ProductFaqs.forEach((faq, index) => {
          formData.append(`ProductFaqs[${index}].Question`, faq.Question);
          formData.append(`ProductFaqs[${index}].Answer`, faq.Answer);
          formData.append(`ProductFaqs[${index}].SortOrder`, faq.SortOrder);
        });

        
      const response = await axios.put(`${UPDATE_PRODUCT_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

  

      dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: [response.data] });
      navigate('/product'); 

    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to update Product');
    }
  };


  export const deleteProductImageRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.delete(`${DELETE_PRODUCT_IMAGE_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: DELETE_PRODUCT_IMAGE_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      console.log(error)
      dispatch({
        type: DELETE_PRODUCT_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Product Image');
    }
  };


  export const deleteProductBannerImageRequest = (id) => async (dispatch) => {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.delete(`${DELETE_PRODUCT_BANNER_IMAGE_URL}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({ type: DELETE_PRODUCT_BANNER_IMAGE_SUCCESS , payload: { deletedId: id } });
    } catch (error) {
      console.log(error)
      dispatch({
        type: DELETE_PRODUCT_FAILURE,
        payload: error.message,
      });
      toast.error('Failed to delete Product Banner Image');
    }
  };
  

  
  
